/**
 *
 * @class Deck
 *
 */

import Tools from '@/class/Tools.class'

import moment from 'moment'
moment.locale( 'fr' )


/**
 * Class
 */

export default class Deck extends Tools {

	/**
	 */

	constructor( day ) {

		super()
		
		this.picks	= []
		this.pts_total	= this.pts_avg = this.estim_total = this.estim_avg = 0
		this.best_pick	= this.hell_pick = false
		
		this.pts_avg	= 0

		this.number	= this.get_deck_number( day )

	}

	/**
	 */

	set_picks( picks ) {

		this.picks	= picks

	}

	/**
	 */
	
	set_days_averages() {

		var _this	= this
		var days_picks	= []

		this.picks.forEach(function( pick ) {

			pick.score	= parseInt( pick.score )
			pick.estimation	= parseInt( pick.estimation )
			
			days_picks.push( pick )

			if ( _this.best_pick === false || _this.best_pick.score < pick.score ) {
				_this.best_pick	= pick
			}

			if ( _this.hell_pick === false || _this.hell_pick.score > pick.score ) {
				_this.hell_pick	= pick
			}

			_this.pts_total	+= pick.score
			_this.estim_total	+= pick.estimation

		})

		days_picks	= days_picks.reduce(( a, b ) => {
			a[b.date]	= a[b.date] ? a[b.date].concat(b) : [b]
			return a
		}, {} )

		var days_points	= []
		var days_estim	= []

		Tools.jsonEach( days_picks, function( picks ) {
			days_points.push( picks.reduce(( partial_sum, a ) => partial_sum + a.score, 0 ) )
			days_estim.push( picks.reduce(( partial_sum, a ) => partial_sum + a.estimation, 0 ) )
		})

		this.pts_avg	= Math.round( ( days_points.reduce(( a, b ) => a + b, 0 ) / days_points.length ) * 100 ) / 100
		this.estim_avg	= Math.round( ( days_estim.reduce(( a, b ) => a + b, 0 ) / days_estim.length ) * 100 ) / 100

	}

	/**
	 */

	set_best_pick() {

		var _this	= this

		if ( this.picks.length > 0 ) {

			this.picks.forEach( pick => {
				
				if ( _this.best_pick ) {

					if ( _this.best_pick.score < pick.score ) {
						_this.best_pick	= pick
					}
					
				} else {
					_this.best_pick	= pick
				}

			})

		}

	}

}