<template>

	<div
		v-if="!day_passed"
		id="daily-dashboard"
		ref="group"
		class="group"
		:class="{ screenshot : screenshot, nodata : nodata }"
	>

		<div class="screenshot-top" v-if="screenshot">

			<img src="@/assets/img/logo-random-ttfl.png" />

			<div>

				<div class="title">
					<div>Daily recap</div>
					<div class="black">Pick <em>#</em>{{ pick_number }}</div>
				</div>

				<div>
					<div class="black">Deck <em>#</em>{{ deck_number }}</div>
					<div>
						Points : <span :class="{ red: ( rungood < 0 ), green : ( rungood >= 0 ) }">
							{{ day_picks_total ? day_picks_total : 0 }} PTS <em>(</em><span :class="{ red : ( rungood < 0 ), green : ( rungood >= 0 ) }" v-html="rungood ? ( rungood > 0 ? '<em>+</em>' + rungood : rungood ) : 0" /><em>)</em>
						</span>
					</div>
				</div>

				<div>
					<div>
						Classement : <span :class="{ red : ( evo < 0 ), green : ( evo >= 0 ) }">{{ day_ranking }}</span>
					</div>
					<div>
						Evo : <span :class="{ red : ( evo < 0 ), green : ( evo >= 0 ) }" v-html="evo ? ( evo > 0 ? '<em>+</em>' + evo : evo ) : 0" />
					</div>
				</div>

			</div>

		</div>

		<container
			ref="group_container"
			class="container-datas"
			:classes="{ noheader : screenshot, loading : !loaded }"
			:collapse_on_mount="true"
			:header_toggle="false"
			:screenshot_function="take_screenshot"
			:screenshot="screenshot"
			:tweet_content="tweet_content"
		>

			<template slot="container-head">

				<div class="group-title"  :class="{ loading : !loaded }">
					Daily
				</div>

				<div class="deck-number even">
					<span>Deck #{{ deck_number }}</span>
				</div>
				<div>
					<span>Pick #{{ pick_number }}</span>
				</div>
				<div>
					Classement : <span :class="{ red : ( evo < 0 ), green : ( evo >= 0 ) }">{{ day_ranking }}</span>
				</div>

				<div class="even">
					Evo : <span :class="{ red : ( evo < 0 ), green : ( evo >= 0 ) }" v-html="evo ? ( evo > 0 ? '+' + evo : evo ) : 0" />
				</div>

				<div class="night-score">
					Score nuit : 
					<span :class="{ red: ( ( rungood ) < 0 ), green : ( ( rungood ) >= 0 ) }">
						{{ day_picks_total ? day_picks_total : 0 }} PTS ({{ ( rungood > 0 ) ? '+' + rungood : rungood }})
					</span>
				</div>

			</template>

			<template slot="container-body"><table class="datas-table desktop">
					
				<thead><tr class="thead-main">

					<th class="align-center" v-if="!is_phone()">
						<div>TEAM</div>
					</th>
					<th>
						<div>PSEUDO</div>
					</th>
					<th class="align-center">
						<div>GÉNÉRAL</div>
					</th>
					<th class="th-points align-center">
						<div>POINTS (vs J+1)</div>
					</th>
					<th class="align-center">
						<div>SCORE <span v-if="!is_phone()">(vs ESTIMATION)</span></div>
					</th>
					<th>
						<div>JOUEUR</div>
					</th>
					<th class="align-center">
						<div><img class="emoji" src="@/assets/img/emojis/stars.png" /></div>
					</th>
					<th class="align-center">
						<div>EVO</div>
					</th>

				</tr></thead>

				<tbody><tr
					v-for="( user, key ) in users_vue"
					v-bind:key="key"
					:user="user"
					:class="{ 'row-red' : ( user.evo < 0 ), 'row-green' : ( user.evo > 0 ) }"
				>

					<td class="score" v-if="!is_phone()"><div>
						#{{ ( key + 1 ) }}
					</div></td>

					<td><div>
						{{ !is_phone() ? user.pseudo : user.pseudo.substring( 0, 5 ) + '.' }}
					</div></td>

					<td class="score"><div v-if="user.day_ranking">
						#{{ user.day_ranking.value }}
					</div></td>

					<td class="score"><div>
						{{ user.total_points }} ({{ user.diff_points_m1 }})
					</div></td>
					
					<td class="score" v-if="!is_phone()"><div :class="{ red: ( user.idx_rungood < 0 || ( user.day_pick && user.day_pick.score == 0 ) ), green : ( user.day_pick.score > 0 && user.idx_rungood >= 0 ) }">
						{{ ( !user.day_pick || !user.day_pick.score ) ? 0 : user.day_pick.score }} PTS
						({{ ( user.idx_rungood > 0 ) ? '+' + user.idx_rungood : user.idx_rungood }})
					</div></td>

					<td class="score" v-if="is_phone()"><div :class="{ red: ( user.idx_rungood < 0 || ( user.day_pick && user.day_pick.score == 0 ) ), green : ( user.day_pick.score > 0 && user.idx_rungood >= 0 ) }">
						{{ ( !user.day_pick || !user.day_pick.score ) ? 0 : user.day_pick.score }} PTS<br>
						({{ ( user.idx_rungood > 0 ) ? '+' + user.idx_rungood : user.idx_rungood }})
					</div></td>

					<td class="pick"><div>

						<div>
							<img class="team-logo" v-if="!is_phone() && user.day_pick.team.logo != ''" :src="require( '@/assets/img/logos/' + user.day_pick.team.logo )">
							{{ get_pick_short_name( user.day_pick.player.fname, user.day_pick.player.lname ) }}
						</div>

						<img v-if="!is_phone() && pick_emoji( user.day_pick )" :src="require( '@/assets/img/emojis/' + pick_emoji( user.day_pick ) + '.png' )" class="emoji" />
						
						<div v-if="is_phone()">
							<img class="team-logo" v-if="user.day_pick.team.logo != ''" :src="require( '@/assets/img/logos/' + user.day_pick.team.logo )">
							<img v-if="pick_emoji( user.day_pick )" :src="require( '@/assets/img/emojis/' + pick_emoji( user.day_pick ) + '.png' )" />
						</div>
						
					</div></td>

					<td class="score"><div>
						{{ user.total_bestpicks }}
					</div></td>

					<td class="score"><div>
						<span :class="{ red : ( user.evo < 0 ), green : ( user.evo >= 0 ) }">{{ user.evo }}</span>
					</div></td>

				</tr></tbody>

			</table></template>

		</container>

	</div>

</template>

<script>

	import tools from '@/mixins/tools'
	import device from '@/mixins/device'
	import mixins_container from '@/mixins/container'

	import api from '@/api'
	import Tools from '@/class/Tools.class'
	import Deck from '@/class/Deck.class'

	export default {

		components	: {
			container	: () => import( '@/components/container' ),
		},

		mixins	: [
			tools,
			device,
			mixins_container,
		],

		props	: [ 'users', 'day' ],

		data	: function() { return {

			loaded	: false,
			datas_loaded	: 0,
			nodata	: false,

			day_before	: false,
			day_passed	: false,

			picks	: [],
			rankings	: [],
			users_api_datas	: [],
			users_vue	: [],

			day_ranking	: 0,
			evo	: 0,
			day_picks_total	: 0,
			day_estims_total	: 0,
			day_diff_total	: 0,
			rungood	: 0,
			deck_number	: 1,
			pick_number	: 1,

			// screenshot	: true,

		}},

		methods	: {

			/**
			 * Tri des joueurs selon une donnée
			 * @params object sorting
			 */

			sort( sorting ) {

				return this.users_vue.slice().sort( ( a , b ) => {

					var current	= sorting.field
					
					var a_val	= parseFloat( a.sorting_values[current] )
					var b_val	= parseFloat( b.sorting_values[current] )

					let modifier	= 1

					if ( sorting.dir === 'desc' ) modifier = -1

					if ( a_val < b_val ) return -1 * modifier
					if ( a_val > b_val ) return 1 * modifier

					return 0

				})

			},

			/**
			 */

			update_table() {

				var _this	= this

				/**
				 * En attente de toutes les données
				 */

				if ( this.rankings.length == 0 || this.users_api_datas.length == 0 ) return false


				this.day_picks_total	= 0
				this.day_estims_total	= 0
				this.users_vue	= []

				this.users_sheet_build()



				var day_ranking	= ( this.rankings[0].date == _this.day_before.format( 'YYYY-MM-DD' ) ) ? this.rankings[0] : this.rankings[1]
				var last_ranking	= ( this.rankings[0].date == _this.day_before.format( 'YYYY-MM-DD' ) ) ? this.rankings[1] : this.rankings[0]

				this.day_ranking	= day_ranking.ranking
				this.evo	= last_ranking.ranking - day_ranking.ranking
				this.rungood	= this.day_picks_total - this.day_estims_total

			},

			/**
			 */

			users_sheet_build() {

				var _this	= this
				// var users_tmp	= []

				_this.users_api_datas.forEach(function( user ) {

					var user_tmp	= user



					/**
					 * Ranking
					 */

					user_tmp.day_ranking	= false
					user_tmp.last_ranking	= false
					user_tmp.sorting_values	= {
						ranking	: false,
					}

					user_tmp.day_ranking	= user.rankings.find( ranking => {
						return ranking.date == _this.day_before.format( 'YYYY-MM-DD' )
					})

					if ( user_tmp.day_ranking ) {
						user_tmp.sorting_values.ranking	= user_tmp.day_ranking.value
					}

					var day_before_before	= _this.$moment( _this.day_before.valueOf() )
					day_before_before.subtract( 1, 'days' )

					user_tmp.last_ranking	= user.rankings.find( ranking => {
						return ranking.date == day_before_before.format( 'YYYY-MM-DD' )
					})

					user_tmp.evo	= 0

					if ( user_tmp.day_ranking && user_tmp.last_ranking ) {
						user_tmp.evo	= user_tmp.last_ranking.value - user_tmp.day_ranking.value
					}

					user_tmp.sorting_values.evo	= user_tmp.evo

					if ( user_tmp.evo > 0 ) user_tmp.evo = '+' + user_tmp.evo

					/**
					 * Pick
					 */

					user_tmp.day_pick.team	= ( user_tmp.day_pick && user_tmp.day_pick.player ) ? Tools.search_team( user_tmp.day_pick.player.team_id ) : {
						logo: '',
						tricode: 'NP',
					}

					if ( !user_tmp.day_pick.player ) {

						user_tmp.day_pick.player	= {
							fname	: '',
							lname	: 'No pick',
						}

					}

					user_tmp.sorting_values.pick	= parseInt( user_tmp.day_pick.score )
					user_tmp.idx_rungood	= !user_tmp.day_pick.estimation ? 0 : user_tmp.day_pick.score - user_tmp.day_pick.estimation
					user_tmp.total_bestpicks	= _this.user_total_bestpicks( user_tmp )

					_this.users_vue.push( user_tmp )



					/**
					 * Team total
					 */

					_this.day_picks_total	+= parseInt( user_tmp.day_pick.score )
					_this.day_estims_total	+= !user_tmp.day_pick.estimation ? 0 : parseInt( user_tmp.day_pick.estimation )

				})

				/**
				 * Classement des membres
				 */

				_this.users_vue	= this.sort({
					field	: 'ranking',
					dir	: 'asc',
				})



				/**
				 * Différence avec M-1
				 */

				_this.users_vue.forEach(function( user, index ) {

					user.diff_points_m1	= 0

					if ( _this.users_vue[index - 1] ) {

						user.diff_points_m1	= _this.users_vue[index - 1].total_points - user.total_points
						user.diff_points_m1	= user.diff_points_m1 * -1

					}

				})



			},

			/**
			 */

			user_pick( user_id ) {
				return this.picks.filter( pick => pick.user_id == user_id )
			},

			/**
			 */

			user_total_bestpicks( user ) {

				var total	= 0

				user.picks.forEach(function( pick ) {
					if ( pick.best_pick == 1 ) total++
				})

				return total

			},

			/**
			 * 
			 */

			get_pick_short_name( fname, lname ) {

				if ( lname != 'No pick' ) {
					fname	= fname.charAt( 0 ) + '. '
				}

				return fname + lname

			},

		},

		watch	: {

			datas_loaded	: function() {

				if ( this.datas_loaded >= 3 ) {

					this.loaded	= true

					if ( this.screenshot ) {
						this.$refs.group_container.show	= true
					}

					if ( !this.picks || this.rankings.length == 0 ) {
						this.nodata	= true
					} else {
						this.update_table()
					}

				}

			},

		},

		computed	: {

			/**
			 */

			get_picks() {
				return this.$store.state.team.picks
			},

			/**
			 */
			
			tweet_content	: function() {

				var rungood	= this.rungood > 0 ? '+' + this.rungood : this.rungood
				var evo	= this.evo > 0 ? '+' + this.evo : this.evo

				var text	= "#TTFL Deck #" + this.deck_number + " - Pick #" + this.pick_number + "\n\n"

				text	+= "TEXT \n\n"
				text	+= ( this.rungood > 0 ) ? "📈" : "📉"
				text	+= " " + ( this.day_picks_total ? this.day_picks_total : 0 ) + " PTS | " + rungood + " PTS vs EST. \n"
				text	+= "📊 " + this.day_ranking + "e | " + evo + "\n\n"
				text	+= " #LetsGoRandomTTFL"

				return text

			},

		},

		mounted	: function() {

			var _this	= this

			_this.day_before	= _this.$moment( _this.day.valueOf() )
			_this.day_before.subtract( 1, 'days' )

			var day_before_before	= _this.$moment( _this.day_before.valueOf() ).subtract( 1, 'days' )

			/**
			 * La nuit n'est pas encore passé = pas de données
			 */
			
			if ( ( _this.$moment().valueOf() - _this.day.valueOf() ) < 0 ) {

				this.day_passed	= true
				return false

			}

			/**
			 */

			this.$store.getters['team/get_rankings']({

				date_start	: day_before_before.format( 'YYYY-MM-DD' ),
				date_end	: _this.day_before.format( 'YYYY-MM-DD' ),

			}, function( datas ) {

				_this.rankings	= datas
				_this.datas_loaded	+= 1

			})

			/**
			 */

			api.get( 'day/pick_number', {
				date	: this.day_before.format( 'YYYY-MM-DD' ),
			}).then(( datas ) => {

				_this.pick_number	= datas.pick_number
				_this.datas_loaded	+= 1

			})

			/**
			 */

			api.get( 'vue/users_datas', {
				date	: _this.day_before.format( 'YYYY-MM-DD' ),
				retrieve_player	: true,
			}).then(( datas ) => {

				_this.users_api_datas	= datas
				_this.datas_loaded	+= 1

			})
			


			var deck	= new Deck( this.day_before )
			this.deck_number	= deck.number

			this.screenshot_filename	= this.day_before.format( 'YYYY-MM-DD' ) + ' - Night - Random Team.jpg'

		},

	}

</script>

<style lang="scss" scoped>

	@import '@/assets/scss/_base';

	@import '@/assets/scss/datas-table';



	/** ---------------------------------------*\
	*---------------------------------------- **/
	
	#team #daily-dashboard:not(.screenshot) .container-body th {

		& > div {
			display: block;
			justify-content: none;
			text-align: left;
		}

		&.align-center > div {
			text-align: center;
		}

	}
	
	/** ---------------------------------------*\
	*---------------------------------------- **/

	#daily-dashboard .container-body td {

		height: 60px;

		img.emoji {
			width: 25px;
			height: 25px;
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	#daily-dashboard.screenshot {

		width: 1100px;
		padding: 25px;
		margin-left: auto;
		margin-right: auto;
		background-color: $red;

		header.container-head {
			width: 100%;
			padding: 0;
		}

		header.container-head > div {
			width: 100%;
			justify-content: space-between;
			margin: 0;
		}

		header.container-head > div > div {
			height: 100%;
		}

		header.container-head > div > div.team-name img {
			width: 75px;
		}

		.night-score {
			border-right: 0;
		}

		// .toggle-container,
		.btn-screenshot {
			display: none;
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	#daily-dashboard.screenshot::v-deep .container-head > div.head-actions {

		display: none;

	}

	/** ---------------------------------------*\
		Head mobile
	*---------------------------------------- **/
	
	#team #daily-dashboard:not(.screenshot)::v-deep .container-head {

		@include mobile() {

			flex-flow: row-reverse wrap;
			padding: 0;

			& > div:not(.head-actions) {
				margin-right: 0;
			}

			& > div:not(.head-actions) > div {

				flex: 50%;
				max-width: 100%;
				border-right: 0;

				&:first-child,
				&:last-child {
					flex: 100%;
				}

			}

			.head-actions {

				position: absolute;
				width: 100%;
				max-width: 100%;
				height: 50px;
				padding-right: 15px;
				top: 0;
				right: 0;
				z-index: 2;

				button {

					color: #FFF;

					&:before, &:after {
						background-color: #FFF;
					}

				}

			}

		}

	}

	/** ---------------------------------------*\
		Tableau mobile
	*---------------------------------------- **/

	#team #daily-dashboard:not(.screenshot) .container-body th,
	#team #daily-dashboard:not(.screenshot) .container-body td {

		@include mobile() {

			padding: 0;
			border-bottom: 0;
			font-size: 8px;
			line-height: 1.3em;

			&.th-points {
				font-size: 7px;
			}

			&.score {
				word-wrap: break-word;
			}

			&.score span.score-day {
				margin-right: 5px;
			}

			&.pick {

				display: flex;
				align-items: center;
				line-height: 1.5em;

				& > div {
					display: block;
					height: auto;
				}

				& > div > div {

					display: flex;
					width: 100%;
					justify-content: flex-start;

					@include mobile() {
						font-size: 8px;
					}

				}

				img {
					width: 15px;
					height: 15px;
				}

			}

			& > div {
				padding: 5px;
			}

		}

	}

</style>