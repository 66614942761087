
import teams_config from '@/assets/json/teams.config.json'

export default {

	data	: function() { return {
	}},

	created	: function () {
	},

	methods	: {

		/**
		 * Formate et retourne l'url d'un match
		 */

		game_url( url ) {
			return url ? 'https://www.nba.com/game/' + url : ''
		},

		/**
		 * Récupération de l'emoji relatif au score d'un pick
		 */

		pick_emoji( pick ) {

			var emoji	= false

			if ( pick.score == 0 ) {
				emoji	= 'poop'
			} else if ( pick.score > 0 && pick.score < 20 ) {
				emoji	= 'carrot'
			} else if ( ( pick.estimation - pick.score ) >= 15 ) {
				emoji	= 'goat'
			} else if ( parseInt( pick.best_pick ) == 1 ) {
				emoji	= 'stars'
			}

			return emoji

		},

		/**
		 */

		ttfl_score_color( score, score_max = 70, score_median = 25 ) {

			var color	= '#ff0000',
				opacity	= 1,
				diff	= 0,
				theclass	= 'good'

			score	= Math.round( score )

			if ( score >= score_median ) {

				diff	= ( score - score_median )

			} else {

				color	= '#105a8e'
				theclass	= 'notgood'
				diff	= ( score_median - score )
				
			}

			opacity	= diff / ( score_max - score_median )
			opacity	+= 0.1

			return {
				value	: score,
				color	: color,
				opacity	: opacity,
				class	: theclass,
			}

		},

		/**
		 * Bon ou mauvais score suivant le paramètre "min"
		 */

		score_good_bad( score, min ) {

			if ( !min ) {
				min	= 0
			}

			if ( score >= min ) {
				return 'good'
			} else {
				return 'bad'
			}

		},

		/**
		 * Différence entre 2 scores
		 */

		score_diff( score, score_2 ) {

			var diff	= score - score_2

			return ( diff > 0 ) ? '+' + diff : diff

		},

		/**
		 */

		ttfl_momentum( value, max = 20 ) {

			var opacity	= 1,
				theclass	= 'notgood',
				final_value	= '-',
				orig_value	= 0;



			if ( value ) {

				orig_value	= value;

				final_value	= value.toFixed( 1 );

				if ( value < 0 ) {
					value	= value * -1;
				} else {
					theclass		= 'good';
					final_value		= '+' + value.toFixed( 1 );
				}

			} else {
				value	= 0;
			}

			opacity	= value / max;

			return {
				'float_value'	: orig_value,
				'value'	: final_value,
				'opacity'	: opacity,
				'class'	: theclass,
			}

		},

		/**
		 */

		ttfl_index( value, median = 0, max = 25 ) {

			var opacity	= 1,
				theclass	= 'good',
				orig_value	= 0,
				final_value	= Math.round( value );



			orig_value	= value;

			if ( value < median ) {
				theclass	= 'notgood';
			}

			opacity	= value / max;

			if ( opacity == 0 ) {
				opacity	= 0.1;
			}

			return {
				'float_value'	: orig_value,
				'value'	: final_value,
				'opacity'	: opacity,
				'class'	: theclass,
			}

		},

		/**
		 */

		search_team( team_id ) {

			var the_team	= false;

			teams_config.forEach(function( team ) {

				if( team.teamId == team_id ) {
					the_team	= team
					return
				}

			})

			return the_team

		},

		/**
		 */

		random_string( length ) {

			var result	= ''
			var characters	= 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			var charactersLength	= characters.length
	
			for ( var i = 0; i < length; i++ ) {
				result += characters.charAt( Math.floor( Math.random() * charactersLength ) )
			}
	
			return result
	
		},

		/**
		 */

		get_mobile_fullname : function( player, max_length ) {

			if ( !max_length )	max_length = 6

			return player.fname.substring( 0, 1 ) + '. ' + ( ( player.lname.length > max_length ) ? player.lname.substring( 0, max_length ) + '.' : player.lname )

		},
	
	},

	mounted	: function() {
	},

}