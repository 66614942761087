
export default {

	data	: function() { return {

		screenshot	: false,	// Activation du template spécial screenshot
		screenshot_filename	: 'Random TTFL - Screenshot',	// Nom du fichier du screen

	}},

	created	: function () {
	},

	methods	: {

		/**
		 * Téléchargement du conteneur en image
		 */
		async take_screenshot() {

			var _this	= this
			this.screenshot	= true

			window.setTimeout(function() {

				_this.$html2canvas( _this.$refs.group, {
					type	: 'dataURL',
					scale	: 2,
					useCORS	: true,
				}).then(function( canvas ) {

					const fileSaver = require( 'file-saver' )
					fileSaver.saveAs( canvas, _this.screenshot_filename )

					_this.screenshot	= false

				})

			}, 1000 )
			
		},
	
	},

	mounted	: function() {
	},

}